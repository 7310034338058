import styled from '@emotion/styled';

import { SPACING } from '@zola/zola-ui/src/styles/emotion';

import Address from 'components/publicWebsiteV2/common/Address';

export const Container = styled.div``;

export const Title = styled.h2`
  margin-top: 0; // Defensive styling for bootstrap styles applied in preview
  margin-bottom: ${SPACING.SM};
  overflow-wrap: anywhere;
`;

export const Venue = styled.div`
  margin-bottom: ${SPACING.SM};

  &:empty {
    display: none;
  }
`;

export const StyledAddress = styled(Address)`
  margin-top: 0; // Defensive styling for bootstrap styles applied in preview
  margin-bottom: ${SPACING.SM};

  &:empty {
    display: none;
  }
`;
