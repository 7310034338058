import _findIndex from 'lodash/findIndex';

/**
 * Created by zoechodosh on 1/13/17.
 */
export const Countries = [
  {
    title: 'United States',
    value: 'US',
  },
  {
    title: 'Afghanistan',
    value: 'AF',
  },
  {
    title: 'Albania',
    value: 'AL',
  },
  {
    title: 'Algeria',
    value: 'DZ',
  },
  {
    value: 'AS',
    title: 'American Samoa',
  },
  {
    value: 'AD',
    title: 'Andorra',
  },
  {
    value: 'AO',
    title: 'Angola',
  },
  {
    value: 'AI',
    title: 'Anguilla',
  },
  {
    value: 'AQ',
    title: 'Antarctica',
  },
  {
    value: 'AG',
    title: 'Antigua and Barbuda',
  },
  {
    value: 'AR',
    title: 'Argentina',
  },
  {
    value: 'AM',
    title: 'Armenia',
  },
  {
    value: 'AW',
    title: 'Aruba',
  },
  {
    value: 'AU',
    title: 'Australia',
  },
  {
    value: 'AT',
    title: 'Austria',
  },
  {
    value: 'AZ',
    title: 'Azerbaijan',
  },
  {
    value: 'BS',
    title: 'Bahamas',
  },
  {
    value: 'BH',
    title: 'Bahrain',
  },
  {
    value: 'BD',
    title: 'Bangladesh',
  },
  {
    value: 'BB',
    title: 'Barbados',
  },
  {
    value: 'BY',
    title: 'Belarus',
  },
  {
    value: 'BE',
    title: 'Belgium',
  },
  {
    value: 'BZ',
    title: 'Belize',
  },
  {
    value: 'BJ',
    title: 'Benin',
  },
  {
    value: 'BM',
    title: 'Bermuda',
  },
  {
    value: 'BT',
    title: 'Bhutan',
  },
  {
    value: 'BO',
    title: 'Bolivia',
  },
  {
    value: 'BQ',
    title: 'Bonaire, Sint Eustatius and Saba',
  },
  {
    value: 'BA',
    title: 'Bosnia and Herzegovina',
  },
  {
    value: 'BW',
    title: 'Botswana',
  },
  {
    value: 'BV',
    title: 'Bouvet Island',
  },
  {
    value: 'BR',
    title: 'Brazil',
  },
  {
    value: 'IO',
    title: 'British Indian Ocean Territory',
  },
  {
    value: 'VG',
    title: 'British Virgin Islands',
  },
  {
    value: 'BN',
    title: 'Brunei',
  },
  {
    value: 'BG',
    title: 'Bulgaria',
  },
  {
    value: 'BF',
    title: 'Burkina Faso',
  },
  {
    value: 'BI',
    title: 'Burundi',
  },
  {
    value: 'KH',
    title: 'Cambodia',
  },
  {
    value: 'CM',
    title: 'Cameroon',
  },
  {
    value: 'CA',
    title: 'Canada',
  },
  {
    value: 'CV',
    title: 'Cape Verde',
  },
  {
    value: 'KY',
    title: 'Cayman Islands',
  },
  {
    value: 'CF',
    title: 'Central African Republic',
  },
  {
    value: 'TD',
    title: 'Chad',
  },
  {
    value: 'CL',
    title: 'Chile',
  },
  {
    value: 'CN',
    title: 'China',
  },
  {
    value: 'CX',
    title: 'Christmas Island',
  },
  {
    value: 'CC',
    title: 'Cocos Islands',
  },
  {
    value: 'CO',
    title: 'Colombia',
  },
  {
    value: 'KM',
    title: 'Comoros',
  },
  {
    value: 'CG',
    title: 'Congo',
  },
  {
    value: 'CK',
    title: 'Cook Islands',
  },
  {
    value: 'CR',
    title: 'Costa Rica',
  },
  {
    value: 'HR',
    title: 'Croatia',
  },
  {
    value: 'CU',
    title: 'Cuba',
  },
  {
    value: 'CW',
    title: 'Curaçao',
  },
  {
    value: 'CY',
    title: 'Cyprus',
  },
  {
    value: 'CZ',
    title: 'Czech Republic',
  },
  {
    value: 'CI',
    title: "Côte d'Ivoire",
  },
  {
    value: 'DK',
    title: 'Denmark',
  },
  {
    value: 'DJ',
    title: 'Djibouti',
  },
  {
    value: 'DM',
    title: 'Dominica',
  },
  {
    value: 'DO',
    title: 'Dominican Republic',
  },
  {
    value: 'EC',
    title: 'Ecuador',
  },
  {
    value: 'EG',
    title: 'Egypt',
  },
  {
    value: 'SV',
    title: 'El Salvador',
  },
  {
    value: 'GQ',
    title: 'Equatorial Guinea',
  },
  {
    value: 'ER',
    title: 'Eritrea',
  },
  {
    value: 'EE',
    title: 'Estonia',
  },
  {
    value: 'ET',
    title: 'Ethiopia',
  },
  {
    value: 'FK',
    title: 'Falkland Islands',
  },
  {
    value: 'FO',
    title: 'Faroe Islands',
  },
  {
    value: 'FJ',
    title: 'Fiji',
  },
  {
    value: 'FI',
    title: 'Finland',
  },
  {
    value: 'FR',
    title: 'France',
  },
  {
    value: 'GF',
    title: 'French Guiana',
  },
  {
    value: 'PF',
    title: 'French Polynesia',
  },
  {
    value: 'TF',
    title: 'French Southern Territories',
  },
  {
    value: 'GA',
    title: 'Gabon',
  },
  {
    value: 'GM',
    title: 'Gambia',
  },
  {
    value: 'GE',
    title: 'Georgia',
  },
  {
    value: 'DE',
    title: 'Germany',
  },
  {
    value: 'GH',
    title: 'Ghana',
  },
  {
    value: 'GI',
    title: 'Gibraltar',
  },
  {
    value: 'GR',
    title: 'Greece',
  },
  {
    value: 'GL',
    title: 'Greenland',
  },
  {
    value: 'GD',
    title: 'Grenada',
  },
  {
    value: 'GP',
    title: 'Guadeloupe',
  },
  {
    value: 'GU',
    title: 'Guam',
  },
  {
    value: 'GT',
    title: 'Guatemala',
  },
  {
    value: 'GG',
    title: 'Guernsey',
  },
  {
    value: 'GN',
    title: 'Guinea',
  },
  {
    value: 'GW',
    title: 'Guinea-Bissau',
  },
  {
    value: 'GY',
    title: 'Guyana',
  },
  {
    value: 'HT',
    title: 'Haiti',
  },
  {
    value: 'HM',
    title: 'Heard Island And McDonald Islands',
  },
  {
    value: 'HN',
    title: 'Honduras',
  },
  {
    value: 'HK',
    title: 'Hong Kong',
  },
  {
    value: 'HU',
    title: 'Hungary',
  },
  {
    value: 'IS',
    title: 'Iceland',
  },
  {
    value: 'IN',
    title: 'India',
  },
  {
    value: 'ID',
    title: 'Indonesia',
  },
  {
    value: 'IR',
    title: 'Iran',
  },
  {
    value: 'IQ',
    title: 'Iraq',
  },
  {
    value: 'IE',
    title: 'Ireland',
  },
  {
    value: 'IM',
    title: 'Isle Of Man',
  },
  {
    value: 'IL',
    title: 'Israel',
  },
  {
    value: 'IT',
    title: 'Italy',
  },
  {
    value: 'JM',
    title: 'Jamaica',
  },
  {
    value: 'JP',
    title: 'Japan',
  },
  {
    value: 'JE',
    title: 'Jersey',
  },
  {
    value: 'JO',
    title: 'Jordan',
  },
  {
    value: 'KZ',
    title: 'Kazakhstan',
  },
  {
    value: 'KE',
    title: 'Kenya',
  },
  {
    value: 'KI',
    title: 'Kiribati',
  },
  {
    value: 'XK',
    title: 'Kosovo',
  },
  {
    value: 'KW',
    title: 'Kuwait',
  },
  {
    value: 'KG',
    title: 'Kyrgyzstan',
  },
  {
    value: 'LA',
    title: 'Laos',
  },
  {
    value: 'LV',
    title: 'Latvia',
  },
  {
    value: 'LB',
    title: 'Lebanon',
  },
  {
    value: 'LS',
    title: 'Lesotho',
  },
  {
    value: 'LR',
    title: 'Liberia',
  },
  {
    value: 'LY',
    title: 'Libya',
  },
  {
    value: 'LI',
    title: 'Liechtenstein',
  },
  {
    value: 'LT',
    title: 'Lithuania',
  },
  {
    value: 'LU',
    title: 'Luxembourg',
  },
  {
    value: 'MO',
    title: 'Macao',
  },
  {
    value: 'MK',
    title: 'Macedonia',
  },
  {
    value: 'MG',
    title: 'Madagascar',
  },
  {
    value: 'MW',
    title: 'Malawi',
  },
  {
    value: 'MY',
    title: 'Malaysia',
  },
  {
    value: 'MV',
    title: 'Maldives',
  },
  {
    value: 'ML',
    title: 'Mali',
  },
  {
    value: 'MT',
    title: 'Malta',
  },
  {
    value: 'MH',
    title: 'Marshall Islands',
  },
  {
    value: 'MQ',
    title: 'Martinique',
  },
  {
    value: 'MR',
    title: 'Mauritania',
  },
  {
    value: 'MU',
    title: 'Mauritius',
  },
  {
    value: 'YT',
    title: 'Mayotte',
  },
  {
    value: 'MX',
    title: 'Mexico',
  },
  {
    value: 'FM',
    title: 'Micronesia',
  },
  {
    value: 'MD',
    title: 'Moldova',
  },
  {
    value: 'MC',
    title: 'Monaco',
  },
  {
    value: 'MN',
    title: 'Mongolia',
  },
  {
    value: 'ME',
    title: 'Montenegro',
  },
  {
    value: 'MS',
    title: 'Montserrat',
  },
  {
    value: 'MA',
    title: 'Morocco',
  },
  {
    value: 'MZ',
    title: 'Mozambique',
  },
  {
    value: 'MM',
    title: 'Myanmar',
  },
  {
    value: 'NA',
    title: 'Namibia',
  },
  {
    value: 'NR',
    title: 'Nauru',
  },
  {
    value: 'NP',
    title: 'Nepal',
  },
  {
    value: 'NL',
    title: 'Netherlands',
  },
  {
    value: 'AN',
    title: 'Netherlands Antilles',
  },
  {
    value: 'NC',
    title: 'New Caledonia',
  },
  {
    value: 'NZ',
    title: 'New Zealand',
  },
  {
    value: 'NI',
    title: 'Nicaragua',
  },
  {
    value: 'NE',
    title: 'Niger',
  },
  {
    value: 'NG',
    title: 'Nigeria',
  },
  {
    value: 'NU',
    title: 'Niue',
  },
  {
    value: 'NF',
    title: 'Norfolk Island',
  },
  {
    value: 'KP',
    title: 'North Korea',
  },
  {
    value: 'MP',
    title: 'Northern Mariana Islands',
  },
  {
    value: 'NO',
    title: 'Norway',
  },
  {
    value: 'OM',
    title: 'Oman',
  },
  {
    value: 'PK',
    title: 'Pakistan',
  },
  {
    value: 'PW',
    title: 'Palau',
  },
  {
    value: 'PS',
    title: 'Palestine',
  },
  {
    value: 'PA',
    title: 'Panama',
  },
  {
    value: 'PG',
    title: 'Papua New Guinea',
  },
  {
    value: 'PY',
    title: 'Paraguay',
  },
  {
    value: 'PE',
    title: 'Peru',
  },
  {
    value: 'PH',
    title: 'Philippines',
  },
  {
    value: 'PN',
    title: 'Pitcairn',
  },
  {
    value: 'PL',
    title: 'Poland',
  },
  {
    value: 'PT',
    title: 'Portugal',
  },
  {
    value: 'PR',
    title: 'Puerto Rico',
  },
  {
    value: 'QA',
    title: 'Qatar',
  },
  {
    value: 'RE',
    title: 'Reunion',
  },
  {
    value: 'RO',
    title: 'Romania',
  },
  {
    value: 'RU',
    title: 'Russia',
  },
  {
    value: 'RW',
    title: 'Rwanda',
  },
  {
    value: 'BL',
    title: 'Saint Barthélemy',
  },
  {
    value: 'SH',
    title: 'Saint Helena',
  },
  {
    value: 'KN',
    title: 'Saint Kitts And Nevis',
  },
  {
    value: 'LC',
    title: 'Saint Lucia',
  },
  {
    value: 'MF',
    title: 'Saint Martin',
  },
  {
    value: 'PM',
    title: 'Saint Pierre And Miquelon',
  },
  {
    value: 'VC',
    title: 'Saint Vincent And The Grenadines',
  },
  {
    value: 'WS',
    title: 'Samoa',
  },
  {
    value: 'SM',
    title: 'San Marino',
  },
  {
    value: 'ST',
    title: 'Sao Tome And Principe',
  },
  {
    value: 'SA',
    title: 'Saudi Arabia',
  },
  {
    value: 'SN',
    title: 'Senegal',
  },
  {
    value: 'RS',
    title: 'Serbia',
  },
  {
    value: 'SC',
    title: 'Seychelles',
  },
  {
    value: 'SL',
    title: 'Sierra Leone',
  },
  {
    value: 'SG',
    title: 'Singapore',
  },
  {
    value: 'SX',
    title: 'Sint Maarten (Dutch part)',
  },
  {
    value: 'SK',
    title: 'Slovakia',
  },
  {
    value: 'SI',
    title: 'Slovenia',
  },
  {
    value: 'SB',
    title: 'Solomon Islands',
  },
  {
    value: 'SO',
    title: 'Somalia',
  },
  {
    value: 'ZA',
    title: 'South Africa',
  },
  {
    value: 'GS',
    title: 'South Georgia And The South Sandwich Islands',
  },
  {
    value: 'KR',
    title: 'South Korea',
  },
  {
    value: 'ES',
    title: 'Spain',
  },
  {
    value: 'LK',
    title: 'Sri Lanka',
  },
  {
    value: 'SD',
    title: 'Sudan',
  },
  {
    value: 'SR',
    title: 'Suriname',
  },
  {
    value: 'SJ',
    title: 'Svalbard And Jan Mayen',
  },
  {
    value: 'SZ',
    title: 'Swaziland',
  },
  {
    value: 'SE',
    title: 'Sweden',
  },
  {
    value: 'CH',
    title: 'Switzerland',
  },
  {
    value: 'SY',
    title: 'Syria',
  },
  {
    value: 'TW',
    title: 'Taiwan',
  },
  {
    value: 'TJ',
    title: 'Tajikistan',
  },
  {
    value: 'TZ',
    title: 'Tanzania',
  },
  {
    value: 'TH',
    title: 'Thailand',
  },
  {
    value: 'CD',
    title: 'The Democratic Republic Of Congo',
  },
  {
    value: 'TL',
    title: 'Timor-Leste',
  },
  {
    value: 'TG',
    title: 'Togo',
  },
  {
    value: 'TK',
    title: 'Tokelau',
  },
  {
    value: 'TO',
    title: 'Tonga',
  },
  {
    value: 'TT',
    title: 'Trinidad and Tobago',
  },
  {
    value: 'TN',
    title: 'Tunisia',
  },
  {
    value: 'TR',
    title: 'Turkey',
  },
  {
    value: 'TM',
    title: 'Turkmenistan',
  },
  {
    value: 'TC',
    title: 'Turks And Caicos Islands',
  },
  {
    value: 'TV',
    title: 'Tuvalu',
  },
  {
    value: 'VI',
    title: 'U.S. Virgin Islands',
  },
  {
    value: 'UG',
    title: 'Uganda',
  },
  {
    value: 'UA',
    title: 'Ukraine',
  },
  {
    value: 'AE',
    title: 'United Arab Emirates',
  },
  {
    value: 'GB',
    title: 'United Kingdom',
  },
  {
    value: 'UM',
    title: 'United States Minor Outlying Islands',
  },
  {
    value: 'UY',
    title: 'Uruguay',
  },
  {
    value: 'UZ',
    title: 'Uzbekistan',
  },
  {
    value: 'VU',
    title: 'Vanuatu',
  },
  {
    value: 'VA',
    title: 'Vatican',
  },
  {
    value: 'VE',
    title: 'Venezuela',
  },
  {
    value: 'VN',
    title: 'Vietnam',
  },
  {
    value: 'WF',
    title: 'Wallis And Futuna',
  },
  {
    value: 'EH',
    title: 'Western Sahara',
  },
  {
    value: 'YE',
    title: 'Yemen',
  },
  {
    value: 'ZM',
    title: 'Zambia',
  },
  {
    value: 'ZW',
    title: 'Zimbabwe',
  },
  {
    value: 'AX',
    title: 'Åland Islands',
  },
];

export const finalFormCountries = Countries.map(({ title, value }) => ({
  label: title,
  value,
}));

export const getCountryFromCountryCode = (countryCode: string): string => {
  const idx = _findIndex(Countries, elem => elem.value === countryCode);
  return idx >= 0 ? Countries[idx].title : '';
};

export const UnitedStates = [
  {
    value: 'AL',
    title: 'Alabama',
  },
  {
    value: 'AK',
    title: 'Alaska',
  },
  {
    value: 'AZ',
    title: 'Arizona',
  },
  {
    value: 'AR',
    title: 'Arkansas',
  },
  {
    value: 'CA',
    title: 'California',
  },
  {
    value: 'CO',
    title: 'Colorado',
  },
  {
    value: 'CT',
    title: 'Connecticut',
  },
  {
    value: 'DE',
    title: 'Delaware',
  },
  {
    value: 'DC',
    title: 'District Of Columbia',
  },
  {
    value: 'FL',
    title: 'Florida',
  },
  {
    value: 'GA',
    title: 'Georgia',
  },
  {
    value: 'HI',
    title: 'Hawaii',
  },
  {
    value: 'ID',
    title: 'Idaho',
  },
  {
    value: 'IL',
    title: 'Illinois',
  },
  {
    value: 'IN',
    title: 'Indiana',
  },
  {
    value: 'IA',
    title: 'Iowa',
  },
  {
    value: 'KS',
    title: 'Kansas',
  },
  {
    value: 'KY',
    title: 'Kentucky',
  },
  {
    value: 'LA',
    title: 'Louisiana',
  },
  {
    value: 'ME',
    title: 'Maine',
  },
  {
    value: 'MD',
    title: 'Maryland',
  },
  {
    value: 'MA',
    title: 'Massachusetts',
  },
  {
    value: 'MI',
    title: 'Michigan',
  },
  {
    value: 'MN',
    title: 'Minnesota',
  },
  {
    value: 'MS',
    title: 'Mississippi',
  },
  {
    value: 'MO',
    title: 'Missouri',
  },
  {
    value: 'MT',
    title: 'Montana',
  },
  {
    value: 'NE',
    title: 'Nebraska',
  },
  {
    value: 'NV',
    title: 'Nevada',
  },
  {
    value: 'NH',
    title: 'New Hampshire',
  },
  {
    value: 'NJ',
    title: 'New Jersey',
  },
  {
    value: 'NM',
    title: 'New Mexico',
  },
  {
    value: 'NY',
    title: 'New York',
  },
  {
    value: 'NC',
    title: 'North Carolina',
  },
  {
    value: 'ND',
    title: 'North Dakota',
  },
  {
    value: 'OH',
    title: 'Ohio',
  },
  {
    value: 'OK',
    title: 'Oklahoma',
  },
  {
    value: 'OR',
    title: 'Oregon',
  },
  {
    value: 'PA',
    title: 'Pennsylvania',
  },
  {
    value: 'RI',
    title: 'Rhode Island',
  },
  {
    value: 'SC',
    title: 'South Carolina',
  },
  {
    value: 'SD',
    title: 'South Dakota',
  },
  {
    value: 'TN',
    title: 'Tennessee',
  },
  {
    value: 'TX',
    title: 'Texas',
  },
  {
    value: 'UT',
    title: 'Utah',
  },
  {
    value: 'VT',
    title: 'Vermont',
  },
  {
    value: 'VA',
    title: 'Virginia',
  },
  {
    value: 'WA',
    title: 'Washington',
  },
  {
    value: 'WV',
    title: 'West Virginia',
  },
  {
    value: 'WI',
    title: 'Wisconsin',
  },
  {
    value: 'WY',
    title: 'Wyoming',
  },
  {
    value: 'AA',
    title: 'Armed Forces Americas',
  },
  {
    value: 'AE',
    title: 'Armed Forces Europe',
  },
  {
    value: 'AP',
    title: 'Armed Forces Pacific',
  },
];

export const finalFormUSStates = UnitedStates.map(({ title, value }) => ({
  label: title,
  value,
}));

export const Canada = [
  {
    value: 'AB',
    title: 'Alberta',
  },
  {
    value: 'BC',
    title: 'British Columbia',
  },
  {
    value: 'MB',
    title: 'Manitoba',
  },
  {
    value: 'NB',
    title: 'New Brunswick',
  },
  {
    value: 'NL',
    title: 'Newfoundland and Labrador',
  },
  {
    value: 'NS',
    title: 'Nova Scotia',
  },
  {
    value: 'ON',
    title: 'Ontario',
  },
  {
    value: 'PE',
    title: 'Prince Edward Island',
  },
  {
    value: 'QC',
    title: 'Quebec',
  },
  {
    value: 'SK',
    title: 'Saskatchewan',
  },
  {
    value: 'NT',
    title: 'Northwest Territories',
  },
  {
    value: 'NU',
    title: 'Nunavut',
  },
  {
    value: 'YT',
    title: 'Yukon',
  },
];

export const finalFormCAProvinces = Canada.map(({ title, value }) => ({
  label: title,
  value,
}));
