import ApiService from '@zola-helpers/client/dist/es/http/api';

import type {
  UpdateRsvpRequest,
  VoidResponse,
  WRsvpData,
  WGuestGroupSearchResult,
} from '@zola/svc-web-api-ts-client';

import type { AppThunk } from 'reducers/useAppDispatch';

import {
  requestGuestRsvp,
  receiveGuestRsvp,
  showWebsiteRsvpSummary,
  receiveGuestSearch,
} from './PublicWebsiteActions';

export function getRsvpByGuestGroupUuidV2(guestGroupUuid: string): AppThunk<Promise<void>> {
  return (dispatch, getState) => {
    dispatch(requestGuestRsvp());
    const weddingAccountUuid = getState().publicWebsite.wedding.wedding_account_uuid;
    return ApiService.get<WRsvpData>(
      `/web-api/v2/publicwedding/rsvp/guest-group/uuid/${guestGroupUuid}/wedding-account/uuid/${weddingAccountUuid}`
    ).then(response => {
      const rsvpResponseNeeded = response.guests?.some(guest =>
        guest.event_invitations?.some(
          invitation => ((invitation.rsvp_type as unknown) as string) === 'NO_RESPONSE'
        )
      );
      const showSummary = !rsvpResponseNeeded;
      dispatch(receiveGuestRsvp(response));
      const noRSVPEvents = (response.events || []).filter(e => e.collect_rsvps).length === 0;
      if (showSummary || noRSVPEvents) dispatch(showWebsiteRsvpSummary(true));
    });
  };
}

export function updateGuestRsvpV3(
  guestRsvpRequest: UpdateRsvpRequest,
  guestGroupdUuid: string
): AppThunk<Promise<void>> {
  return (dispatch, getState) => {
    const { wedding_account_uuid } = getState().publicWebsite.wedding;
    return ApiService.post<VoidResponse>(
      `/web-api/v2/publicwedding/rsvp/wedding-account/uuid/${wedding_account_uuid}`,
      guestRsvpRequest
    )
      .then(() => {
        dispatch(getRsvpByGuestGroupUuidV2(guestGroupdUuid)).catch(() => {
          // do nothing
        });
      })
      .then(() => {
        dispatch(showWebsiteRsvpSummary(true));
      });
  };
}

export function guestSearchV2(guestName: string): AppThunk<Promise<WGuestGroupSearchResult[]>> {
  return (dispatch, getState) => {
    dispatch({ type: 'GUEST_SEARCH' });
    const uuid = getState().publicWebsite.wedding.wedding_account_uuid;
    return ApiService.post<WGuestGroupSearchResult[]>(
      `/web-api/v1/publicwedding/rsvp/guest/wedding-account/uuid/${uuid}/search-groups`,
      { guest_name: guestName }
    ).then(res => {
      dispatch(receiveGuestSearch(res));
      return res;
    });
  };
}
