import React from 'react';
import { Field } from 'react-final-form';
import { InputFieldV2Adapter } from '@zola/zola-ui/src/components/Form/InputFieldV2';
import { CheckboxFieldAdapter } from '@zola/zola-ui/src/components/Form/CheckboxField';
import { DropdownV2Adapter } from '@zola/zola-ui/src/components/Form/Dropdown';
import { RadioFieldAdapter } from '@zola/zola-ui/src/components/Form/RadioField';
import { TextareaFieldAdapter } from '@zola/zola-ui/src/components/Form/TextareaField';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
// TODO: Move file to Zola-ui

dayjs.extend(customParseFormat);

export const composeValidators = (...validators) => (value, allValues) =>
  validators.reduce((error, validator) => error || validator(value, allValues), undefined);

export const FormInput = ({ validate = null, type = 'text', ...rest }) => (
  <Field
    {...rest}
    validate={validate && validate.length ? composeValidators(...validate) : null}
    component={InputFieldV2Adapter}
    type={type}
  />
);

export const FormHiddenInput = props => (
  <Field {...props}>{p => <input {...p} type="hidden" />}</Field>
);

export const FormTextarea = ({ validate = null, type = 'text', ...rest }) => (
  <Field
    {...rest}
    validate={validate && validate.length ? composeValidators(...validate) : null}
    component={TextareaFieldAdapter}
    type={type}
  />
);

export const FormDropdown = ({ name, validate, ...rest }) => {
  return (
    <Field
      name={name}
      validate={validate && validate.length ? composeValidators(...validate) : null}
    >
      {fieldProps => {
        return <DropdownV2Adapter {...rest} {...fieldProps} />;
      }}
    </Field>
  );
};

export const FormRadio = ({ validate = null, ...rest }) => (
  <Field
    component={RadioFieldAdapter}
    validate={validate ? composeValidators(validate) : null}
    type="radio"
    {...rest}
  />
);

export const FormCheckbox = ({ validate = null, name, ...rest }) => {
  return (
    <Field
      name={name}
      validate={validate ? composeValidators(validate) : null}
      component={CheckboxFieldAdapter}
      id={`checkbox-${name}`}
      type="checkbox"
      {...rest}
    />
  );
};

export const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

export const dataFormat = 'YYYY-MM-DD';
export const inputFormat = 'MM/DD/YYYY';
// pre: Date object
// post: string of 'MM/DD/YYYY'
export const defaultFormat = date => {
  return dayjs(date).format(inputFormat);
};

// pre: string of format 'MM/DD/YYYY'
// post: if valid, Date object; if invalid, null
export const defaultParse = date => {
  if (dayjs(date, inputFormat, true).isValid()) {
    return dayjs(date, inputFormat).toDate();
  }
  return undefined;
};
