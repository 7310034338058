import type { WGuestRsvpView } from '@zola/svc-web-api-ts-client';

export enum RsvpAttendingType {
  NO_RESPONSE = 'NO_RESPONSE',
  DECLINED = 'DECLINED',
  ATTENDING = 'ATTENDING',
}

export type RsvpFormValues = {
  guests?: WGuestRsvpView[];
  guest_group_id?: number;
  guest_group_uuid: string;
  wedding_account_uuid: string;
  wedding_account_id: number;
  rsvp_answers?: FormattedRsvpAnswer;
};

export type FormattedRsvpAnswer = {
  [id: string]: { answer: string };
};
