import React, { useState } from 'react';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import { sortByDate } from '@zola-helpers/client/dist/es/util/dateUtils';

import { updateGuestRsvpV3 } from 'actions/PublicWebsiteV2Actions';
import { useAppSelector } from 'reducers/useAppSelector';
import { useAppDispatch } from 'reducers/useAppDispatch';
import { getGuestGroupRsvp } from 'selectors/public/publicWebsiteSelectors';

import type {
  WRsvpData,
  UpdateGuestRsvpRequest,
  UpdateGuestRsvpAnswerRequest,
  WRsvpEventView,
} from '@zola/svc-web-api-ts-client';
import type { RsvpFormValues } from 'components/publicWebsiteV2/pages/Rsvp/types';

import FormWizard from './components/FormWizard';
import GuestRsvpEventDetails from '../GuestRsvpEventDetails';
import EventRsvp from './components/EventRsvp';

type GuestRsvpFormProps = {
  guestGroupUuid: string;
  previewEvents?: WRsvpEventView[];
};

const GuestRsvpForm = ({ guestGroupUuid, previewEvents }: GuestRsvpFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const guestGroupRsvp: WRsvpData = useAppSelector(getGuestGroupRsvp);
  const [formValues, setFormValues] = useState<RsvpFormValues | undefined>(undefined);

  const { events = [] } = guestGroupRsvp;
  // we're doing extra work here. svc-wedding is already sorting chronologically, svc-web-api re-sorts by display order, and now web-wedding will sort again chronologically
  // Remove this sort after we sunset website v1, and adjsut svc-web-api to send down chronologically
  const sortedEvents = events.sort((event1, event2) =>
    sortByDate(event1.start_at as string, event2.start_at as string)
  );

  const handleSubmitRsvp = (values: RsvpFormValues) => {
    const { guests, rsvp_answers = {}, guest_group_uuid, wedding_account_uuid } = values;
    // ids were stringified as `key${id}` in formatRsvpQuestionsAnswer.ts
    const answers = Object.keys(rsvp_answers).map(key => ({
      rsvp_question_id: Number(key.substring(3)),
      answer: rsvp_answers?.[key]?.answer || '',
    }));

    const formattedRsvpRequest = {
      wedding_account_uuid,
      guest_group_uuid,
      guests: (guests as unknown) as UpdateGuestRsvpRequest[],
      rsvp_answers: (answers as unknown) as UpdateGuestRsvpAnswerRequest[],
    };

    dispatch(updateGuestRsvpV3(formattedRsvpRequest, guestGroupUuid)).catch(() => {
      dispatch(
        toastsActions.negative({
          headline: 'Hm... Something went wrong. Please reach out to the couple about your RSVP.',
          dismissText: `Reload`,
          autoDismissInSeconds: 0,
          onDismiss: () => window.location.reload(),
        })
      );
      throw Error('Error completing RSVP');
    });
  };

  const handlePartialSubmit = (values: RsvpFormValues) => {
    // mostly for the purpose of using a temp name if guest name originally unknown
    setFormValues(values);
  };

  return (
    <FormWizard onSubmit={handleSubmitRsvp} onPartialSubmit={handlePartialSubmit}>
      {(previewEvents || sortedEvents).map(event => (
        <div key={event.id}>
          <GuestRsvpEventDetails event={event} />
          <EventRsvp event={event} formValues={formValues} />
        </div>
      ))}
    </FormWizard>
  );
};

export default GuestRsvpForm;
