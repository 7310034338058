import React, { useMemo } from 'react';

import type { WRsvpEventView, WRsvpData, WGuestRsvpView } from '@zola/svc-web-api-ts-client';
import capitalizeStartCase from '@zola-helpers/client/dist/es/transformers/capitalizeStartCase';
import EventQuestions from 'components/publicWebsiteV2/pages/Rsvp/components/GuestRsvpForm/components/EventQuestions';
import NoRsvpNeeded from 'components/publicWebsiteV2/pages/Rsvp/components/GuestRsvpForm/components/NoRsvpNeeded';
import GuestRsvp from 'components/publicWebsiteV2/pages/Rsvp/components/GuestRsvpForm/components/GuestRsvp';

import { useAppSelector } from 'reducers/useAppSelector';
import { getGuestGroupRsvp } from 'selectors/public/publicWebsiteSelectors';
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';

import type { RsvpFormValues } from 'components/publicWebsiteV2/pages/Rsvp/types';

import { RsvpContainer } from '../GuestRsvpForm.styles';

type EventRsvpProps = {
  event: WRsvpEventView;
  formValues?: RsvpFormValues;
};

const EventRsvp = ({ event, formValues }: EventRsvpProps): JSX.Element => {
  const guestGroupRsvp: WRsvpData = useAppSelector(getGuestGroupRsvp);
  const {
    state: { inPreview },
  } = useWebsiteThemeContext();

  const { guests } = guestGroupRsvp;

  const fakeGuest = [
    {
      id: 123456,
      prefix: null,
      first_name: null,
      middle_name: null,
      family_name: null,
      suffix: null,
      rsvp: 'NO_RESPONSE',
      meal_option_id: null,
    },
  ];

  const formattedMealOptions = useMemo(
    () =>
      event.meal_options?.map(({ id, name }) => ({
        value: id,
        label: capitalizeStartCase(name as string),
      })) || [],
    [event.meal_options]
  );

  return (
    <RsvpContainer>
      {event.collect_rsvps ? (
        <>
          <ul>
            {(inPreview ? ((fakeGuest as unknown) as WGuestRsvpView[]) : guests)?.map(
              (guest, index) => (
                <GuestRsvp
                  key={index}
                  guest={guest}
                  index={index}
                  eventId={event.id as number}
                  formValues={formValues}
                  mealOptions={formattedMealOptions}
                />
              )
            )}
          </ul>
          <EventQuestions questions={event.rsvp_questions} />
        </>
      ) : (
        <NoRsvpNeeded />
      )}
    </RsvpContainer>
  );
};

export default EventRsvp;
