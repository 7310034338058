import styled from '@emotion/styled';

import { SPACING, MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';

export const SearchContainer = styled.div`
  padding: 60px ${SPACING.S32};
  max-width: 800px;
  margin: ${SPACING.LG} auto 0;
  border-radius: 20px;
  text-align: center;
`;

export const Instructions = styled.p`
  text-align: center;
  max-width: 650px;
  margin: 0 auto ${SPACING.S24};
`;

export const Form = styled.form`
  max-width: 400px;
  margin: 0 auto;
`;

export const DesktopLineBreak = styled.br`
  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    display: none;
  }
`;
