import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { SPACING, FONT, MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';

import { CheckboxField } from '@zola/zola-ui/src/components/Form/CheckboxField';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { mapModeThemeToCheckboxStyles } from 'components/publicWebsiteV2/util/genericFormElements.styles';
import { StyledCheckmarkIcon } from '../../GuestRsvpSummary.styles';

export const Container = styled.div<{ containerHeight?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${SPACING.S32};
  max-width: 800px;
  margin: 0 auto;
  border-radius: 20px;
  ${({ containerHeight }) => containerHeight && `height: ${containerHeight}px;`}
`;

export const Title = styled.p`
  font-weight: bold !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 ${SPACING.S24};

  ${MEDIA_QUERY.MOBILE} {
    flex-direction: column;

    ${StyledCheckmarkIcon} {
      margin-bottom: ${SPACING.SM};
    }
  }
`;

export const Form = styled.form`
  display: flex;
  justify-content: center;

  ${MEDIA_QUERY.MOBILE} {
    flex-direction: column;
    align-items: center;
  }
`;

export const InputContainer = styled.div`
  width: 294px;
  margin-right: ${SPACING.MD};

  ${MEDIA_QUERY.MOBILE} {
    margin: 0 auto ${SPACING.MD};
  }

  [class*='-InputSection'] {
    margin-top: 0;
  }
`;

export const StyledCheckbox = styled(CheckboxField)<{
  isDarkMode?: boolean;
  bodyFontColor?: string;
}>`
  display: flex;
  justify-content: center;
  margin: ${SPACING.S24} 0;

  &${({ isDarkMode, bodyFontColor }) => mapModeThemeToCheckboxStyles(isDarkMode, bodyFontColor)}
    ${MEDIA_QUERY.MOBILE} {
    text-align: left;

    [class*='field-wrapper-inner'] {
      align-items: flex-start !important;
    }
  }
`;

export const SubText = styled.p`
  ${FONT.textSizeUltraCompact(FONT.FONT_SIZE_SMALLER)};
`;

export const StyledLink = styled(LinkV2, {
  shouldForwardProp: prop => prop !== 'colorOverride',
})<Partial<{ colorOverride: string }>>`
  font-size: ${FONT.FONT_SIZE_SMALL}!important;
  ${({ colorOverride }) => `
  color: #${colorOverride}!important;

  &:hover {
    color: #${colorOverride};
  }
`}
`;

export const buttonStyles = css`
  width: 100px;
`;
