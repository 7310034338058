import type { WGuestDetailSearchResult } from '@zola/svc-web-api-ts-client';

const formatGuest = (guest: WGuestDetailSearchResult): string | undefined => {
  let guestOutput;
  if (guest.first_name && guest.family_name) {
    guestOutput = `${guest.first_name} ${guest.family_name}`;
  } else if (guest.first_name) {
    guestOutput = `${guest.first_name}`;
  } else if (guest.family_name) {
    guestOutput = `${guest.family_name}`;
  } else {
    guestOutput = ((guest.relationship_type as unknown) as string) === 'CHILD' ? 'Child' : 'Guest';
    return guestOutput;
  }
  if (guest.suffix) {
    return `${guestOutput} ${guest.suffix}`;
  }
  return guestOutput;
};

export default formatGuest;
