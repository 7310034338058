import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { SPACING, MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';

export const Above = styled.p`
  margin-bottom: ${SPACING.MD};
`;

export const Below = styled.p`
  & + & {
    margin-top: ${SPACING.S24};
  }
`;

export const Container = styled.div`
  margin-top: ${SPACING.S24};
  text-align: left;

  ${MEDIA_QUERY.DESKTOP} {
    margin-top: ${SPACING.XL};
  }

  &:empty {
    display: none;
  }
  ul {
    margin-bottom: 0;
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;
  }
`;

export const BelowContainer = styled.div`
  margin-top: ${SPACING.S24};
  text-align: center;

  ${MEDIA_QUERY.DESKTOP} {
    margin-top: ${SPACING.XL};
  }
`;

const mobileSpacing = css`
  ${MEDIA_QUERY.MOBILE} {
    margin-bottom: ${SPACING.S24};
  }
`;

export const Guests = styled.ul`
  ${mobileSpacing}
`;

export const Location = styled.span`
  &:not(:empty) {
    ${mobileSpacing}
  }
`;

export const Guest = styled.li`
  ${MEDIA_QUERY.MOBILE} {
    text-align: center;
  }
`;
