import styled from '@emotion/styled';

import { SPACING, MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';

export const Row = styled.li<{ borderColor: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #${({ borderColor }) => borderColor};
  padding: ${SPACING.S32} 0;

  &:last-of-type {
    border-bottom: 1px solid #${({ borderColor }) => borderColor};
  }

  ${MEDIA_QUERY.MOBILE} {
    flex-direction: column;
    padding: ${SPACING.MD} 0;
  }
`;
