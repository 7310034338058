import React, { useState, useMemo, useEffect, useRef } from 'react';
import { withTypes, FormRenderProps } from 'react-final-form';
import _flatMap from 'lodash/flatMap';
import pluralize from '@zola-helpers/client/dist/es/transformers/pluralize';

import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';

import { useAppSelector } from 'reducers/useAppSelector';
import {
  getGuestGroupRsvp,
  getWeddingAccountId,
  getWeddingAccountUuid,
} from 'selectors/public/publicWebsiteSelectors';
import formatRsvpQuestionsAnswers from 'components/publicWebsiteV2/util/formatRsvpQuestionsAnswers';
import scrollViewTo from 'util/scrollViewTo';

import type { WRsvpData, WRsvpQuestionView } from '@zola/svc-web-api-ts-client';
import type { RsvpFormValues } from 'components/publicWebsiteV2/pages/Rsvp/types';

import {
  EventsLength,
  ButtonContainer,
  BackButton,
  StyledLink,
  FormWizardContainer,
} from '../GuestRsvpForm.styles';

type FormWizardProps = {
  onSubmit: (values: RsvpFormValues) => void;
  onPartialSubmit: (values: RsvpFormValues) => void;
};

const FormWizard: React.FC<FormWizardProps> = ({ onSubmit, onPartialSubmit, children }) => {
  const guestGroupRsvp: WRsvpData = useAppSelector(getGuestGroupRsvp);
  const wedding_account_id: number = useAppSelector(getWeddingAccountId);
  const wedding_account_uuid: string = useAppSelector(getWeddingAccountUuid);
  const [event, setEvent] = useState(0);
  const activeEvent = React.Children.toArray(children)[event];
  const eventsLength = React.Children.count(children);
  const isLastPage = event === eventsLength - 1;
  const ref = useRef<HTMLDivElement | null>(null);

  const { Form: FinalForm } = withTypes<RsvpFormValues>();
  const {
    guests,
    guest_group_id,
    guest_group_uuid,
    rsvp_answers: answers = [],
    events = [],
  } = guestGroupRsvp;

  const {
    state: {
      components: { styleCmsEntityBodyFont, globalAccentColor, ThemedButton },
      inPreview,
    },
  } = useWebsiteThemeContext();

  const handleNext = (values: RsvpFormValues) => {
    setEvent(Math.min(event + 1, React.Children.count(children)));
    onPartialSubmit(values);
  };

  const handlePrev = () => {
    setEvent(Math.max(event - 1, 0));
  };

  const handleSubmitForm = (values: RsvpFormValues) => {
    /*
      Note:
      The act of submitting the form for each event/page allows react-final-form to validate all of the fields currently on the page.
      If you try to bypass this by simply using an onClick handler, RFF gets confused and doesn't validate properly.
      That's why all buttons are type="submit" but we don't actually hit the API until isLastPage
    */
    if (isLastPage) {
      return onSubmit(values);
    }
    return handleNext(values);
  };

  const rsvp_answers = useMemo(
    () =>
      formatRsvpQuestionsAnswers(
        _flatMap(events, e => e.rsvp_questions) as WRsvpQuestionView[],
        answers
      ),
    [events, answers]
  );

  const initialValues: RsvpFormValues = {
    guests,
    guest_group_id,
    guest_group_uuid,
    wedding_account_id,
    wedding_account_uuid,
    rsvp_answers,
  };

  useEffect(() => {
    if (!inPreview) scrollViewTo({ el: ref.current, behavior: 'smooth' });
  }, [activeEvent, inPreview]);

  const StyledEventsLength = styleCmsEntityBodyFont(EventsLength);
  const StyledLinkInline = styleCmsEntityBodyFont(StyledLink);

  return (
    <FormWizardContainer id="form-wizard" ref={ref}>
      {eventsLength > 0 && (
        <StyledEventsLength withSpacing={!(event > 0 && eventsLength > 1)}>
          {pluralize(`1 event`, ` ${event + 1} of ${eventsLength} events`, eventsLength, false)}
        </StyledEventsLength>
      )}
      {event > 0 && eventsLength > 1 && (
        <BackButton>
          <StyledLinkInline
            arrow
            arrowPosition="prefix"
            colorOverride={globalAccentColor}
            role="button"
            onClick={handlePrev}
          >
            Back
          </StyledLinkInline>
        </BackButton>
      )}
      <FinalForm onSubmit={handleSubmitForm} initialValues={initialValues}>
        {({ handleSubmit, invalid }: FormRenderProps): JSX.Element => (
          <form
            onSubmit={e => {
              handleSubmit(e)?.catch(() => undefined);
            }}
          >
            {activeEvent}
            {eventsLength > 0 && (
              <ButtonContainer>
                <ThemedButton type="submit" disabled={invalid}>
                  {isLastPage ? `Submit ${eventsLength > 1 ? 'all' : ''}` : 'Next Event'}
                </ThemedButton>
              </ButtonContainer>
            )}
          </form>
        )}
      </FinalForm>
    </FormWizardContainer>
  );
};

export default FormWizard;
