import React from 'react';
import { Field } from 'react-final-form';

import type { WRsvpQuestionView } from '@zola/svc-web-api-ts-client';

import { MAX_LENGTH_800 } from '@zola/zola-ui/src/components/Form/util/validations';
import { composeValidators } from '@zola/zola-ui/src/components/Form/util/composeValidators';

import FormTextarea from '@zola/zola-ui/src/components/Form/TextareaFieldV2/TextareaFieldV2Adapter';
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';
import { mapFontColorToBgOverlayWithOpacity } from 'components/publicWebsiteV2/util/mappers';

import { QuestionsContainer, QuestionsText, Question } from '../GuestRsvpForm.styles';

type EventQuestionsProps = {
  questions?: WRsvpQuestionView[];
};

const EventQuestions = ({ questions = [] }: EventQuestionsProps): JSX.Element | null => {
  const {
    state: {
      components: {
        styleCmsEntityBodyFont,
        cmsEntityComponentBodyFontValues,
        globalInputFieldStyles,
      },
    },
  } = useWebsiteThemeContext();

  if (!questions.length) return null;
  const StyledQuestionsText = styleCmsEntityBodyFont(QuestionsText);
  return (
    <QuestionsContainer
      style={{
        backgroundColor: mapFontColorToBgOverlayWithOpacity(cmsEntityComponentBodyFontValues.color),
      }}
    >
      <StyledQuestionsText>The couple would like to know...</StyledQuestionsText>
      <ul>
        {questions.map(question => (
          <Question key={question.id} css={globalInputFieldStyles}>
            <Field
              component={FormTextarea}
              id={`rsvp-question-${question.id}-answer`}
              label={`${question.question} (Optional)`}
              maxChars={800}
              name={`rsvp_answers.key${question.id}.answer`}
              validate={composeValidators(MAX_LENGTH_800)}
            />
          </Question>
        ))}
      </ul>
    </QuestionsContainer>
  );
};

export default EventQuestions;
