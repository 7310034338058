import React from 'react';

// types
import type { WCmsRsvpPageView, WCmsEventView } from '@zola/svc-web-api-ts-client';

// components
import TitleImgDesc from 'components/publicWebsiteV2/common/TitleImgDesc';
import GuestSearchForm from 'components/publicWebsiteV2/pages/Rsvp/components/GuestSearchForm';
import GuestRsvpForm from 'components/publicWebsiteV2/pages/Rsvp/components/GuestRsvpForm';
import GuestRsvpSummary from 'components/publicWebsiteV2/pages/Rsvp/components/GuestRsvpSummary';

// utils
import getNonHomeCmsHeroImg from 'components/publicWebsiteV2/util/getNonHomeCmsHeroImg';

// redux
import { useAppSelector } from 'reducers/useAppSelector';
import { useAppDispatch } from 'reducers/useAppDispatch';
import { getGuestGroupRsvp, getRsvpShowSummary } from 'selectors/public/publicWebsiteSelectors';
import {
  resetGuestSearch,
  resetGuestRsvp,
  showWebsiteRsvpSummary,
} from 'actions/PublicWebsiteActions';

// context
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';

// styles
import { useFlyoutPanelContext } from 'components/publicWebsiteV2/contexts/flyoutPanelContext';
import { Container, FlyoutContent } from './Rsvp.styles';

export type RsvpSPProps = {
  pageData?: WCmsRsvpPageView;
};

const RsvpSPFlyoutContent = ({
  inPreview,
  uuid,
  events,
}: {
  inPreview?: boolean;
  uuid: string;
  events: WCmsEventView[] | undefined;
}) => {
  const showSummary = useAppSelector(getRsvpShowSummary);

  return (
    <FlyoutContent data-testid="RsvpSpFlyoutContent">
      {showSummary ? (
        <GuestRsvpSummary />
      ) : (
        <GuestRsvpForm guestGroupUuid={uuid} previewEvents={inPreview ? events : undefined} />
      )}
    </FlyoutContent>
  );
};

const RsvpSP: React.FC<RsvpSPProps> = ({ pageData }) => {
  const dispatch = useAppDispatch();

  const {
    state: { inPreview, enableInPreviewInteractions },
  } = useWebsiteThemeContext();
  const {
    actions: { showFlyoutPanel },
  } = useFlyoutPanelContext();

  const guestGroupRsvp = useAppSelector(getGuestGroupRsvp);

  const { title, description, header_image_url, events, images } = pageData || {};

  const cmsHeaderImgUrl = getNonHomeCmsHeroImg(images);

  const onSelectGuestUuid = (uuid: string) => {
    showFlyoutPanel({
      content: <RsvpSPFlyoutContent uuid={uuid} inPreview={Boolean(inPreview)} events={events} />,
      onClose: () => {
        dispatch(resetGuestSearch()); // clear the guest search results
        dispatch(resetGuestRsvp()); // reset guestGroupRsvp so we show the form again
        dispatch(showWebsiteRsvpSummary(false)); // make sure we don't get locked into the summary after one successful trip through the flow
      },
    });
  };

  return (
    <Container
      className={
        inPreview === 'DESKTOP' && enableInPreviewInteractions?.rsvp
          ? 'IcEnableContainerAndCursor'
          : ''
      }
      data-testid="RsvpSP"
    >
      <TitleImgDesc
        title={title}
        url={cmsHeaderImgUrl || header_image_url}
        description={description}
      />
      {!guestGroupRsvp.guest_group_uuid && (
        <GuestSearchForm onSelectGuestUuid={onSelectGuestUuid} />
      )}
    </Container>
  );
};

export default RsvpSP;
