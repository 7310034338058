const getOffsetTop = (element: HTMLElement) => {
  const firstChildOfBody = document.getElementsByTagName('body')[0];
  let currentEl: HTMLElement = element;
  let { offsetTop } = currentEl;

  while (currentEl.offsetParent && currentEl !== firstChildOfBody) {
    offsetTop += (currentEl.offsetParent as HTMLElement).offsetTop;
    currentEl = currentEl.offsetParent as HTMLElement;
  }

  return offsetTop;
};

const scrollViewTo = ({
  id,
  offset,
  el,
  behavior = 'auto',
  containerId,
}: {
  id?: string;
  offset?: number;
  el?: HTMLElement | null;
  behavior?: ScrollBehavior;
  containerId?: string;
}): void => {
  const element = el || (id && document.getElementById(id));
  const containerEl = containerId ? document.getElementById(containerId) : window;
  const onlyRunGetOffsetTopOnWindow = containerId
    ? offset || 0
    : (element ? getOffsetTop(element) : 0) - (offset || 0);
  if (element && containerEl) {
    containerEl.scrollTo({ left: 0, top: onlyRunGetOffsetTopOnWindow, behavior });
  }
};

export default scrollViewTo;

export function isElementInViewport(element: HTMLElement): boolean {
  const coordinates = element.getBoundingClientRect();
  return (
    coordinates.top >= 0 &&
    coordinates.left >= 0 &&
    coordinates.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    coordinates.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
