import styled from '@emotion/styled';

import { SPACING, MEDIA_QUERY, FONT } from '@zola/zola-ui/src/styles/emotion';

import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormFieldContainer = styled.div`
  padding: ${SPACING.S32};
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  max-width: 800px;
  margin: 0 auto ${SPACING.LG};
  text-align: left;
  border-radius: 20px;

  ${MEDIA_QUERY.DESKTOP} {
    padding: ${SPACING.S32} 150px;
    grid-template-columns: 1fr 1fr;
    div:nth-of-type(n + 3) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
`;

export const Title = styled.h2`
  margin: 60px 0;
`;

export const StyledLink = styled(LinkV2, {
  shouldForwardProp: prop => prop !== 'colorOverride',
})<Partial<{ colorOverride: string }>>`
  align-self: flex-start;
  font-size: ${FONT.FONT_SIZE_SMALL}!important;
  ${({ colorOverride }) => `
    color: #${colorOverride}!important;
    text-align: center;
    &:hover {
      color: #${colorOverride};
    }
  `}
`;
