import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
`;

export const FlyoutContent = styled.div`
  overflow-y: auto;
  text-align: center;
  padding: ${({ theme }) => `0 ${theme.SPACING.MD} ${theme.SPACING.XL} ${theme.SPACING.MD}`};
`;
