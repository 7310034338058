// types
import type { WRsvpEventView } from '@zola/svc-web-api-ts-client';

// utils
import { formatDateUtc } from '@zola-helpers/client/dist/es/util/dateUtils';

export const checkEventEndsSameDay = (event: WRsvpEventView) => {
  if (event.end_at && event.start_at) {
    return (
      formatDateUtc(event.end_at, 'YYYY-MM-DD') === formatDateUtc(event.start_at, 'YYYY-MM-DD')
    );
  }
  return false;
};

export const formatRsvpEventDates = (event: WRsvpEventView) => {
  if (event.end_at && event.start_at) {
    return `${formatDateUtc(event.start_at, `dddd, MMMM D, YYYY h:mm A`)} - ${formatDateUtc(
      event.end_at,
      `${checkEventEndsSameDay(event) ? 'h:mm A' : 'dddd, MMMM D, YYYY h:mm A'}`
    )}`;
  }
  if (event.start_at) {
    return `${formatDateUtc(event.start_at, `dddd, MMMM D, YYYY h:mm A`)}`;
  }
  return '';
};
