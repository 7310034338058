import React from 'react';
import { SPACING } from '@zola/zola-ui/src/styles/emotion';

import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';

import { NoRsvpContainer } from '../GuestRsvpForm.styles';

const NoRsvpNeeded = (): JSX.Element => {
  const {
    state: {
      components: { styleCmsEntityBodyFont, cmsEntityComponentBodyFontValues },
      wedding,
    },
  } = useWebsiteThemeContext();

  const { color: borderColor } = cmsEntityComponentBodyFontValues;

  const P = styleCmsEntityBodyFont('p');

  return (
    <NoRsvpContainer style={{ borderTopColor: `#${borderColor}` }}>
      <P style={{ marginBottom: SPACING.S24 }}>You are invited to this event!</P>
      <P>
        {wedding?.owner_first_name} &amp; {wedding?.partner_first_name} are not collecting online
        RSVPs for this event, so just take note of the necessary details if you&#39;re attending!
      </P>
    </NoRsvpContainer>
  );
};

export default NoRsvpNeeded;
