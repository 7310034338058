import React, { useRef, useState, useEffect } from 'react';
import { getCookie, deleteCookie } from '@zola-helpers/client/dist/es/util/storage';
import { usePrevious } from '@zola/zola-ui/src/hooks/usePrevious';

import InputFieldV3 from '@zola/zola-ui/src/components/Form/inputV3/InputFieldV3';
import GuestSearchResults from 'components/publicWebsiteV2/pages/Rsvp/components/GuestSearchResults';
import GuestRequestRsvpAccess from 'components/publicWebsiteV2/pages/Rsvp/components/GuestRequestRsvpAccess';

import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';

import { getRsvpByGuestGroupUuidV2, guestSearchV2 } from 'actions/PublicWebsiteV2Actions';
import { useAppSelector } from 'reducers/useAppSelector';
import { useAppDispatch } from 'reducers/useAppDispatch';
import {
  getRsvpSearchResults,
  getGuestRsvpAccessForm,
} from 'selectors/public/publicWebsiteSelectors';
import type { WGuestGroupSearchResult } from '@zola/svc-web-api-ts-client';

import { mapFontColorToBgOverlayWithOpacity } from 'components/publicWebsiteV2/util/mappers';

import { SPACING } from '@zola/zola-ui/src/styles/emotion';
import { PageAnimationWrapper } from 'components/publicWebsiteV2/common/PageAnimationWrapper/PageAnimationWrapper';
import { SearchContainer, Instructions, Form, DesktopLineBreak } from './GuestSearchForm.styles';

type GuestSearchFormProps = {
  onSelectGuestUuid: (uuid: string) => void;
};

const GuestSearchForm = ({ onSelectGuestUuid }: GuestSearchFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState('');
  const prevSearchValue = usePrevious(searchValue);
  const [errorMsg, setErrorMsg] = useState('');
  const [rsvpSearchDisabled, setRsvpSearchDisabled] = useState(false);
  const [haveSearched, setHaveSearched] = useState(false);
  const enableSearchTimeout = useRef<number>();
  const rsvpSearchResults = useAppSelector(getRsvpSearchResults);
  const showAccessForm = useAppSelector(getGuestRsvpAccessForm);
  const searchResults: WGuestGroupSearchResult[] | string[] = useAppSelector(getRsvpSearchResults);

  const {
    state: {
      components: {
        cmsEntityComponentBodyFontValues,
        styleCmsEntityBodyFont,
        globalInputFieldStyles,
        ThemedButton,
      },
    },
  } = useWebsiteThemeContext();

  const handleSelectGuest = (guestUuid: string) => {
    deleteCookie('guestSearchesNotActedOn');
    deleteCookie('rsvpSearchDisabled');
    dispatch(getRsvpByGuestGroupUuidV2(guestUuid))
      .then(() => {
        onSelectGuestUuid(guestUuid);
      })
      .catch(err => {
        throw new Error(err.message || 'There was an error fetching guest information');
      });
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (!searchValue) {
      setErrorMsg('Required');
    } else {
      dispatch(guestSearchV2(searchValue))
        .then(() => {
          const notFoundErrorMsg =
            "Hm... we can't find your name. Make sure you enter your name exactly as it appears on your invitation.";
          const notFound = searchResults[0] === 'NOT_FOUND';
          if (notFound) {
            setErrorMsg(notFoundErrorMsg);
          }
          setHaveSearched(true);
        })
        .catch((err: Error) => {
          setHaveSearched(true);
          throw new Error(err?.message || 'There was an error during search');
        });
    }
  };

  const handleInputField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (errorMsg === 'Required' && value) setErrorMsg('');
    setSearchValue(value);
  };

  const updateDisableSearch = () => {
    const searchDisabledUntil = parseInt(getCookie('rsvpSearchDisabled'), 10);
    const searchDisabled = !!searchDisabledUntil;

    if (searchDisabled) {
      const timeNow = new Date().getTime();
      enableSearchTimeout.current = window.setTimeout(
        () => setRsvpSearchDisabled(false),
        searchDisabledUntil - timeNow
      );
      // window.scrollTo(0, 0);
    }

    setRsvpSearchDisabled(searchDisabled);
  };

  useEffect(() => {
    updateDisableSearch();
    return () => clearTimeout(enableSearchTimeout.current);
  }, []);

  useEffect(() => {
    updateDisableSearch();
  }, [rsvpSearchResults]);

  useEffect(() => {
    /* Clear error message when user starts typing something new */
    if (errorMsg && searchValue !== prevSearchValue) setErrorMsg('');
  }, [searchValue, prevSearchValue, errorMsg]);

  const StyledInstructions = styleCmsEntityBodyFont(Instructions);

  return showAccessForm ? (
    <GuestRequestRsvpAccess />
  ) : (
    <PageAnimationWrapper>
      <SearchContainer
        style={{
          backgroundColor: mapFontColorToBgOverlayWithOpacity(
            cmsEntityComponentBodyFontValues.color
          ),
        }}
      >
        <StyledInstructions>
          {rsvpSearchDisabled ? (
            <>
              Looks like you’ve entered your name a few times. Please reach out to the couple and
              request access to their RSVP page.
            </>
          ) : (
            <>
              {' '}
              Please enter the first and last name of one member of your party below.{' '}
              <DesktopLineBreak />
              If you&apos;re responding for you and a guest (or your family), you&apos;ll be able to
              RSVP for your entire group on the next page.
            </>
          )}
        </StyledInstructions>
        {!rsvpSearchDisabled && (
          <Form onSubmit={handleSearch} css={globalInputFieldStyles}>
            <InputFieldV3
              id="guest-search-form"
              onChange={handleInputField}
              placeholder="First and Last name"
              helperText=" Ex. Sarah Fortune (not The Fortune Family or Dr. & Mr. Fortune)"
              value={searchValue}
              isControlled
              errorMessage={errorMsg}
            />
            <ThemedButton style={{ width: '200px', marginTop: SPACING.S24 }} type="submit">
              Continue
            </ThemedButton>
          </Form>
        )}
      </SearchContainer>
      <GuestSearchResults
        setErrorMsg={setErrorMsg}
        onSelectGuest={handleSelectGuest}
        haveSearched={haveSearched}
        searchDisabled={rsvpSearchDisabled}
      />
    </PageAnimationWrapper>
  );
};

export default GuestSearchForm;
