import type {
  WGuestGroupSearchResult,
  WGuestDetailSearchResult,
} from '@zola/svc-web-api-ts-client';

const sortByRelationshipType = (
  guest1: WGuestDetailSearchResult,
  guest2: WGuestDetailSearchResult
) => {
  const rank = { CHILD: 2, PARTNER: 1, PRIMARY: 0 };
  return (
    rank[(guest1.relationship_type as unknown) as keyof typeof rank] -
    rank[(guest2.relationship_type as unknown) as keyof typeof rank]
  );
};

const formatGuestSearchResults = (
  searchResults: WGuestGroupSearchResult[]
): WGuestGroupSearchResult[] => {
  return searchResults.reduce((acc, guestGroup) => {
    const guests = guestGroup?.guests?.sort(sortByRelationshipType);
    const formattedGuestGroup: WGuestGroupSearchResult = Object.assign({}, guestGroup, { guests });
    acc.push(formattedGuestGroup);
    return acc;
  }, [] as WGuestGroupSearchResult[]);
};

export default formatGuestSearchResults;
