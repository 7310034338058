import React from 'react';
import type { WRsvpData } from '@zola/svc-web-api-ts-client';
import capitalizeStartCase from '@zola-helpers/client/dist/es/transformers/capitalizeStartCase';
import { sortByDate } from '@zola-helpers/client/dist/es/util/dateUtils';

import { useAppSelector } from 'reducers/useAppSelector';
import { useAppDispatch } from 'reducers/useAppDispatch';
import { getGuestGroupRsvp } from 'selectors/public/publicWebsiteSelectors';
import { showWebsiteRsvpSummary } from 'actions/PublicWebsiteActions';

import formatGuest from 'components/publicWebsiteV2/util/formatGuest';
import formatRsvpAttendingType from 'components/publicWebsiteV2/util/formatRsvpAttendingType';

import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';
import { RsvpAttendingType } from 'components/publicWebsiteV2/pages/Rsvp/types';

import GuestRsvpEventDetails from 'components/publicWebsiteV2/pages/Rsvp/components/GuestRsvpEventDetails';
import NoRsvpNeeded from 'components/publicWebsiteV2/pages/Rsvp/components/GuestRsvpForm/components/NoRsvpNeeded';
import { isDarkColor } from 'pages/publicStyleUtils/utils.styles';
import EmailForm from './components/EmailForm';

import {
  Title,
  SubTitle,
  EventsContainer,
  EventContainer,
  Guests,
  StatusContainer,
  Status,
  StyledCheckmarkIcon,
  StyledRemoveIcon,
  StyledRsvpListRow,
} from './GuestRsvpSummary.styles';

const GuestRsvpSummary = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const guestGroupRsvp: WRsvpData = useAppSelector(getGuestGroupRsvp);

  const { events = [], guests = [] } = guestGroupRsvp;

  const {
    state: {
      components: {
        styleCmsEntityBodyFont,
        styleCmsEntityHeaderFont,
        ThemedButton,
        backgroundColors,
      },
    },
  } = useWebsiteThemeContext();

  const isDarkMode = isDarkColor(backgroundColors.content);

  const handleEditRsvp = () => dispatch(showWebsiteRsvpSummary(false));

  const hasRSVPEvents = events.filter(e => e.collect_rsvps).length > 0;
  // we're doing extra work here. svc-wedding is already sorting chronologically, svc-web-api re-sorts by display order, and now web-wedding will sort again chronologically
  // Remove this sort after we sunset website v1, and adjsut svc-web-api to send down chronologically
  const sortedEvents = events.sort((event1, event2) =>
    sortByDate(event1.start_at as string, event2.start_at as string)
  );
  const StyledTitle = styleCmsEntityHeaderFont(Title);
  const StyledSubtitle = styleCmsEntityHeaderFont(SubTitle);
  const GuestName = styleCmsEntityBodyFont('div');
  const StyledStatusContainer = styleCmsEntityBodyFont(StatusContainer);

  return (
    <>
      {hasRSVPEvents && (
        <>
          <StyledTitle>Thank you for your RSVP</StyledTitle>
          <EmailForm />
          <StyledSubtitle>Your RSVP Response</StyledSubtitle>
        </>
      )}
      <EventsContainer>
        {sortedEvents.map(event => (
          <EventContainer key={event.id}>
            <GuestRsvpEventDetails event={event} smallerHeaderFont />
            {event.collect_rsvps ? (
              <Guests>
                {guests.map(guest => {
                  const { rsvp_type, meal_option_id } =
                    guest.event_invitations?.find(i => i.event_id === event.id) || {};
                  const { name: mealOption } =
                    event.meal_options?.find(meal => meal.id === meal_option_id) || {};
                  return (
                    <StyledRsvpListRow key={guest.uuid}>
                      <GuestName>{formatGuest(guest)}</GuestName>
                      <StyledStatusContainer>
                        <Status>
                          {((rsvp_type as unknown) as RsvpAttendingType) ===
                          RsvpAttendingType.ATTENDING ? (
                            <StyledCheckmarkIcon height={24} width={24} isDarkMode={isDarkMode} />
                          ) : (
                            <StyledRemoveIcon height={24} width={24} isDarkMode={isDarkMode} />
                          )}
                          {formatRsvpAttendingType((rsvp_type as unknown) as RsvpAttendingType)}
                        </Status>
                        {meal_option_id && (
                          <div>Meal Option: {capitalizeStartCase(mealOption as string)}</div>
                        )}
                      </StyledStatusContainer>
                    </StyledRsvpListRow>
                  );
                })}
              </Guests>
            ) : (
              <NoRsvpNeeded />
            )}
          </EventContainer>
        ))}
      </EventsContainer>
      {hasRSVPEvents && <ThemedButton onClick={handleEditRsvp}>Edit RSVP</ThemedButton>}
    </>
  );
};

export default GuestRsvpSummary;
