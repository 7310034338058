import { RsvpAttendingType } from 'components/publicWebsiteV2/pages/Rsvp/types';

const formatRsvpAttendingType = (type: RsvpAttendingType): string => {
  switch (type) {
    case RsvpAttendingType.ATTENDING:
      return 'Will Attend';
    case RsvpAttendingType.DECLINED:
      return 'Will Not Attend';
    case RsvpAttendingType.NO_RESPONSE:
    default:
      return 'No Response';
  }
};

export default formatRsvpAttendingType;
